import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateConversionTags } from '../../Api/conversionTagApi';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { TOAST_MESSAGE } from '../../Common/constant_text';

function ConversionTagList({ data, isEditing, setIsEditing, labelDb }) {
  const [conversionTags, setConversionTags] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);

  useEffect(() => {
    setConversionTags(data);
  }, [data]);

  const handleChange = (e) => {
    const splitName = e.target.name.split('.');
    let newArray = [...inputUpdate];
    const elementsIndex = conversionTags.findIndex(
      (element) => element.id === splitName[1],
    );
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [splitName[0]]: e.target.value,
    };
    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(conversionTags);
    setIsEditing(true);
  };
  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    updateConversionTags(inputUpdate)
      .then((res) => {
        const { data } = res;
        if (data.data) {
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
    setConversionTags(inputUpdate);
    setIsEditing(false);
  };

  const renderDescription = (tag) => {
    switch (tag) {
      case '%%LOGIN_HOME%%':
        return 'URL：HOME';
      case '%NICKNAME%':
        return labelDb?.label_nickName?.content;
      case '%%LOGIN_INFO_1%%':
        return 'URL：お知らせ-1';
      case '%%LOGIN_INFO_2%%':
        return 'URL：お知らせ-2';
      case '%%LOGIN_INFO_3%%':
        return 'URL：お知らせ-3';
      case '%%LOGIN_INFO_4%%':
        return 'URL：お知らせ-4';
      case '%%LOGIN_INFO_5%%':
        return 'URL：お知らせ-5';
      case '%%LOGIN_INFO_6%%':
        return 'URL：お知らせ-6';
      case '%%LOGIN_INFO_7%%':
        return 'URL：お知らせ-7';
      case '%%LOGIN_INFO_8%%':
        return 'URL：お知らせ-8';
      case '%PROF_POINT%':
        return '残ポイント';
      case '%%LOGIN_BUY_POINT%%':
        return 'URL：ポイント購入';
      case '%%LOGIN_MYPAGE%%':
        return 'URL：マイページ';
      case '%SITE_NAME%':
        return 'サイト名';
      case '%URL_LP%':
        return 'URL：LPページ';
      case '%MAIL_ADDRESS%':
        return 'サポートメールアドレス';
      case '%PHONE_NUMBER%':
        return 'サポート電話番号';
      case '%URL_CONTACT%':
        return 'URL：問合せ（ログイン前）';
      case '%%LOGIN_MYPROFILE%%':
        return 'URL：MYプロフ確認';
      case '%%LOGIN_CHAT_ROOM%%':
        return 'URL：チャットルーム';
      case '%%PROFILE_SQLID_UUID%%':
        return 'URL+ID：ユーザー紹介';
      case '%%SELF_INTRODUCTION%%':
        return 'URL:自己PRページ';
      case '%%LOGIN_CHAT_MESSAGE%%':
        return 'URL: チャットメッセージ';
      default:
        return '';
    }
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>説明</TableCell>
              <TableCell>Tag（貼付用）</TableCell>
              <TableCell>ContentMail（メールに表示）</TableCell>
              <TableCell>PreviewMail（管理画面に表示）</TableCell>
              <TableCell>ContentSms（SMSに表示）</TableCell>
              <TableCell>PreviewSms（管理画面に表示）</TableCell>
              <TableCell>表示順(管理）</TableCell>
            </TableRow>
            {conversionTags.map((item, key) => (
              <TableRow key={key}>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: 50,
                  }}
                >
                  {renderDescription(item.tag)}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {item.tag}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.contentMail}
                      name={`contentMail.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.contentMail
                  )}
                </TableCell>

                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.previewMail}
                      name={`previewMail.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.previewMail
                  )}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.contentSms}
                      name={`contentSms.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.contentSms
                  )}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 500,
                    minWidth: 200,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.previewSms}
                      name={`previewSms.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.previewSms
                  )}
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 100,
                  }}
                >
                  {isEditing ? (
                    <input
                      defaultValue={item.display_order}
                      name={`display_order.${item.id}`}
                      onChange={handleChange}
                    />
                  ) : (
                    item.display_order
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ConversionTagList;
